import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import apiConfig from "../../../apiConfig";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const navigate = useNavigate();
  const [parentIds, setParentIds] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [specifications, setSpecifications] = useState([]);
  const [specificationEntries, setSpecificationEntries] = useState([]);
  const [sizeEntries, setSizeEntries] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    brand: "",
    description: "",
    regularPrice: "",
    salePrice: "",
    stock: "",
    category: "",
    variations: [
      {
        color: "",
        ram: "",
        rom: "",
        variationTitle: "",
        price: "",
        stock: "",
      },
    ],
    variationImage1: null,
    variationImage2: null,
    variationImage3: null,
  });

  useEffect(() => {
    const fetchParentIds = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setParentIds(response.data);
        if (response.data.length > 0) {
          setSelectedParentId(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching parent IDs:", error);
      }
    };

    fetchParentIds();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedParentId) {
          const response = await axios.get(
            `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
          );
          setSubcategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, [selectedParentId]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );
        setBrands(response.data);
        console.log(response);
        if (response.data.length > 0) {
          setSelectedBrand("");
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchSpecifications = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}/admin/GetAllspecificationkeys`
        );
        if (response.data && Array.isArray(response.data.data)) {
          setSpecifications(response.data.data.map((spec) => spec.key));
        } else {
          console.error(
            "Specifications data is not an array or is missing:",
            response.data
          );
          setSpecifications([]);
        }
      } catch (error) {
        console.error("Error fetching specifications:", error);
        setSpecifications([]);
      }
    };

    fetchSpecifications();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      const selectedCategory = parentIds.find(
        (category) => category.name === value
      );
      setSelectedParentId(selectedCategory ? selectedCategory._id : "");
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleAddSpecification = () => {
    setSpecificationEntries((prevEntries) => [
      ...prevEntries,
      { key: "", values: [""] },
    ]);
  };

  const handleRemoveSpecification = (index) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.filter((_, i) => i !== index)
    );
  };

  const handleSpecificationChange = (index, event) => {
    const { value } = event.target;
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, key: value } : entry
      )
    );
  };

  const handleValueChange = (specIndex, valueIndex, event) => {
    const { value } = event.target;
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === specIndex
          ? {
              ...entry,
              values: entry.values.map((val, j) =>
                j === valueIndex ? value : val
              ),
            }
          : entry
      )
    );
  };

  const handleAddValue = (index) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, values: [...entry.values, ""] } : entry
      )
    );
  };

  const handleRemoveValue = (specIndex, valueIndex) => {
    setSpecificationEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === specIndex
          ? {
              ...entry,
              values: entry.values.filter((_, j) => j !== valueIndex),
            }
          : entry
      )
    );
  };

  const handleVariationChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const updatedVariations = [...prevFormData.variations];
      updatedVariations[index] = {
        ...updatedVariations[index],
        [field]: value,
      };
      return {
        ...prevFormData,
        variations: updatedVariations,
      };
    });
  };

  const handleAddProduct = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("brand", selectedBrand);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("regularPrice", formData.regularPrice);
    formDataToSend.append("salePrice", formData.salePrice);
    formDataToSend.append("stock", formData.stock);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("subcategory", selectedSubcategory);
    formDataToSend.append("productimage1", formData.productimage1);
    formDataToSend.append("productimage2", formData.productimage2);
    formDataToSend.append("productimage3", formData.productimage3);
    formDataToSend.append("variations", JSON.stringify(formData.variations));
    formDataToSend.append("size", JSON.stringify(sizeEntries));
    formDataToSend.append(
      "specifications",
      JSON.stringify(specificationEntries)
    );

    formData.variations.forEach((variation, index) => {
      if (variation.variationImage1) {
        formDataToSend.append(
          `variationImage1_${index}`,
          variation.variationImage1
        );
      }
      if (variation.variationImage2) {
        formDataToSend.append(
          `variationImage2_${index}`,
          variation.variationImage2
        );
      }
      if (variation.variationImage3) {
        formDataToSend.append(
          `variationImage3_${index}`,
          variation.variationImage3
        );
      }
    });

    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.addProducts}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Neetu----", response.data);
      setShowModal(true);
      setFormData({
        title: "",
        brand: "",
        description: "",
        regularPrice: "",
        salePrice: "",
        stock: "",
        category: "",
        productimage1: null,
        productimage2: null,
        productimage3: null,
        variations: [
          {
            color: "",
            ram: "",
            rom: "",
            variationTitle: "",
            price: "",
            stock: "",
          },
        ],
      });
      setSpecificationEntries([]);
    } catch (error) {
      console.error(
        "Error adding product:",
        error.response?.data || error.message
      );
    }
  };

  const handleVariation = () => {
    setFormData((prevState) => ({
      ...prevState,
      variations: [
        ...prevState.variations,
        {
          color: "",
          ram: "",
          rom: "",
          variationTitle: "",
          price: "",
          stock: "",
        },
      ],
    }));
  };

  const handleRemoveVariation = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      variations: prevState.variations.filter((_, i) => i !== index),
    }));
  };

  const handleImageChange1 = (index, event) => {
    const files = event.target.files;
    const updatedImages = [...formData.variations];

    if (event.target.id === `variationImage1_${index}`) {
      updatedImages[index].variationImage1 = files[0];
    } else if (event.target.id === `variationImage2_${index}`) {
      updatedImages[index].variationImage2 = files[0];
    } else if (event.target.id === `variationImage3_${index}`) {
      updatedImages[index].variationImage3 = files[0];
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      variations: updatedImages,
    }));
  };

  const handleAddSize = () => {
    setSizeEntries((prev) => [...prev, ""]);
  };

  const handleRemoveSize = (valueIndex) => {
    setSizeEntries((prev) => prev.filter((_, index) => index !== valueIndex));
  };

  const handleValueChangeSize = (valueIndex, event) => {
    const updatedValue = event.target.value;
    setSizeEntries((prev) => {
      const newValues = [...prev];
      newValues[valueIndex] = updatedValue;
      return newValues;
    });
  };

  const handleSubmit = () => {
    const filteredValues = [
      ...new Set(
        sizeEntries.map((value) => value.trim()).filter((value) => value !== "")
      ),
    ];

    console.log(filteredValues);
  };

  return (
    <div className="container-fluid px-4">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group mb-3">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            className="form-control"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            {parentIds.map((parent) => (
              <option key={parent._id} value={parent.name}>
                {parent.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="subcategory">Subcategory</label>
          <select
            id="subcategory"
            name="subcategory"
            className="form-control"
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
          >
            <option value="">Select Subcategory</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory.name} value={subcategory.name}>
                {subcategory.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="brand">Brand</label>
          <select
            id="brand"
            name="brand"
            className="form-control"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            <option value="">Select Brand</option>
            {brands.map((brand) => (
              <option key={brand.name} value={brand.name}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description">Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="regularPrice">Regular Price</label>
          <input
            type="text"
            className="form-control"
            id="regularPrice"
            name="regularPrice"
            value={formData.regularPrice}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="salePrice">Sale Price</label>
          <input
            type="text"
            className="form-control"
            id="salePrice"
            name="salePrice"
            value={formData.salePrice}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="stock">Stock</label>
          <input
            type="text"
            className="form-control"
            id="stock"
            name="stock"
            value={formData.stock}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="productimage1">Product Image 1</label>
          <input
            type="file"
            className="form-control"
            id="productimage1"
            name="productimage1"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="productimage2">Product Image 2</label>
          <input
            type="file"
            className="form-control"
            id="productimage2"
            name="productimage2"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="productimage3">Product Image 3</label>
          <input
            type="file"
            className="form-control"
            id="productimage3"
            name="productimage3"
            onChange={handleImageChange}
          />
        </div>

        {sizeEntries.map((value, valueIndex) => (
          <div key={valueIndex} className="d-flex mb-2">
            <input
              type="text"
              placeholder="Enter Size Value (e.g., S, M, L)"
              className="form-control me-2"
              value={value}
              onChange={(event) => handleValueChangeSize(valueIndex, event)}
            />
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveSize(valueIndex)}
            >
              Remove Value
            </button>
          </div>
        ))}

        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddSize}
        >
          Add Size
        </button>

        <div className="form-group mb-3">
          <label htmlFor="specifications"></label>
          <div className="d-flex align-items-center mb-2">
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleAddSpecification}
            >
              Add Specification
            </button>
          </div>
          {specificationEntries?.map((specification, specIndex) => (
            <div key={specIndex} className="mb-2">
              <div className="d-flex mb-2">
                <select
                  className="form-control me-2"
                  value={specification?.key}
                  onChange={(event) =>
                    handleSpecificationChange(specIndex, event)
                  }
                >
                  <option value="">Select Key</option>
                  {specifications.map((specKey) => (
                    <option key={specKey} value={specKey}>
                      {specKey}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveSpecification(specIndex)}
                >
                  Remove Specification
                </button>
              </div>
              {specification?.values.map((value, valueIndex) => (
                <div key={valueIndex} className="d-flex mb-2">
                  <input
                    type="text"
                    placeholder="Value"
                    className="form-control me-2"
                    value={value}
                    onChange={(event) =>
                      handleValueChange(specIndex, valueIndex, event)
                    }
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveValue(specIndex, valueIndex)}
                  >
                    Remove Value
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-primary mb-2"
                onClick={() => handleAddValue(specIndex)}
              >
                Add Value
              </button>
            </div>
          ))}
        </div>

        {formData.variations.map((variation, index) => (
          <>
            <form key={index}>
              <div className="form-group mb-3">
                <h2>Variations</h2>
                <label htmlFor="variationTitle">Title:</label>
                <input
                  type="text"
                  className="form-control"
                  id="variationTitle"
                  name="variationTitle"
                  value={variation.variationTitle}
                  onChange={(e) =>
                    handleVariationChange(
                      index,
                      "variationTitle",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="price">price:</label>
                <input
                  type="text"
                  className="form-control"
                  id="price"
                  name="price"
                  value={variation.price}
                  onChange={(e) =>
                    handleVariationChange(index, "price", e.target.value)
                  }
                />
              </div>
              <div>
                <label>Color:</label>
                <input
                  type="text"
                  name="color"
                  value={variation.color}
                  onChange={(e) =>
                    handleVariationChange(index, "color", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="ram">RAM:</label>
                <input
                  type="text"
                  name="ram"
                  value={variation.ram}
                  onChange={(e) =>
                    handleVariationChange(index, "ram", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="rom">ROM:</label>
                <input
                  type="text"
                  name="rom"
                  value={variation.rom}
                  onChange={(e) =>
                    handleVariationChange(index, "rom", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="stock">stock:</label>
                <input
                  type="text"
                  name="stock"
                  value={variation.stock}
                  onChange={(e) =>
                    handleVariationChange(index, "stock", e.target.value)
                  }
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor={`variationImage1_${index}`}>
                  Product Image 1
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`variationImage1_${index}`}
                  name={`variationImage1_${index}`}
                  onChange={(e) => handleImageChange1(index, e)}
                  multiple={false}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor={`variationImage2_${index}`}>
                  Product Image 2
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`variationImage2_${index}`}
                  name={`variationImage2_${index}`}
                  onChange={(e) => handleImageChange1(index, e)}
                  multiple={false}
                />
              </div>

              <div className="form-group mb-3">
                <label htmlFor={`variationImage3_${index}`}>
                  Product Image 3
                </label>
                <input
                  type="file"
                  className="form-control"
                  id={`variationImage3_${index}`}
                  name={`variationImage3_${index}`}
                  onChange={(e) => handleImageChange1(index, e)}
                  multiple={false}
                />
              </div>
            </form>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveVariation(index)}
            >
              Remove Variation
            </button>
          </>
        ))}

        <div style={{ display: "flex", margin: 10 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleVariation()}
          >
            Add Variation
          </button>
        </div>

        <div style={{ display: "flex", margin: 10 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddProduct}
          >
            Add Product
          </button>
        </div>
      </form>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Product Added</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your product has been added successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProduct;

import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const ViewSubcategory = () => {
  const [parentIds, setParentIds] = useState([]);
  const [selectedParentId, setSelectedParentId] = useState("");
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    const fetchParentIds = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setParentIds(response.data);
        if (response.data.length > 0) {
          setSelectedParentId(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching parent IDs:", error);
      }
    };

    fetchParentIds();
  }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        if (selectedParentId) {
          const response = await axios.get(
            `${apiConfig.baseUrl}${apiConfig.getSubCategory}${selectedParentId}`
          );
          setSubcategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubcategories();
  }, [selectedParentId]);

  const handleParentIdChange = (e) => {
    console.log("Selected parent ID:", e.target.value);
    setSelectedParentId(e.target.value);
  };

  const handleDeleteSubcategory = async (subcategoryId) => {
    try {
      const response = await axios.delete(
        `${apiConfig.baseUrl}${apiConfig.deleteSubCategory}${subcategoryId}`
      );
      console.log("Subcategory deleted:", response.data);
      // After successful deletion, update the subcategories state to reflect the changes
      setSubcategories(
        subcategories.filter((subcategory) => subcategory._id !== subcategoryId)
      );
      alert("subcateory deleted successfully");
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  return (
    <div>
      <h2>Select Parent Category:</h2>
      <select value={selectedParentId} onChange={handleParentIdChange}>
        {parentIds.map((parentId) => (
          <option key={parentId._id} value={parentId._id}>
            {parentId.name}
          </option>
        ))}
      </select>

      {selectedParentId && (
        <div>
          <h2>Subcategories for Parent ID: {selectedParentId}</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Subcategory Id</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {subcategories.map((subcategory) => (
                <tr key={subcategory._id}>
                  <td>{subcategory.name}</td>
                  <td>{subcategory._id}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDeleteSubcategory(subcategory._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ViewSubcategory;

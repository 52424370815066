import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const AddCoupon = () => {
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [applicableProducts, setApplicableProducts] = useState([]);
  const [applicableCategories, setApplicableCategories] = useState([]);
  const [applicableBrands, setApplicableBrands] = useState([]);
  const [TnC, setTnc] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [isWelcome, setIsWelcome] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const addCoupon = async () => {
    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("Title", title);
      formData.append("code", code);
      formData.append("discount", discount);
      formData.append("discountType", discountType);
      formData.append("applicableProducts", JSON.stringify(applicableProducts));
      formData.append("applicableCategories", applicableCategories);
      formData.append("applicableBrands", applicableBrands[0]);
      formData.append("TnC", TnC);
      formData.append("description", description);
      formData.append("endDate", endDate);
      formData.append("usageLimit", usageLimit);
      formData.append("isWelcome", isWelcome ? "true" : "false");
      formData.append("isActive", isActive ? "true" : "false");

      if (images && images.length > 0) {
        Array.from(images).forEach((file) => formData.append("images", file));
      } else {
        alert("No images selected");
        return;
      }

      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.AddCoupon}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("Response data:", response.data);
      alert("Coupon added successfully!");
      resetForm();
    } catch (error) {
      console.error(
        "Error details:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Error adding coupon: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setTitle("");
    setCode("");
    setDiscount("");
    setDiscountType("");
    setApplicableProducts([]);
    setApplicableCategories();
    setApplicableBrands();
    setTnc("");
    setDescription("");
    setEndDate("");
    setUsageLimit("");
    setIsWelcome(true);
    setIsActive(true);
    setImages([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setCategories(categoryResponse.data);

        const brandResponse = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );
        setBrands(brandResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleApplicableProductsChange = (e) => {
    const products = e.target.value.split(",").map((item) => item.trim());
    setApplicableProducts(products); 
  };

  const handleApplicableCategoriesChange = (e) => {
    const categories = e.target.value.split(",").map((item) => item.trim());
    setApplicableCategories(categories);
  };

  const handleApplicableBrandsChange = (e) => {
    const brands = e.target.value.split(",").map((item) => item.trim());
    setApplicableBrands(brands);
  };

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Add New Coupon</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addCoupon();
        }}
        style={styles.form}
      >
        <label style={styles.label}>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            style={styles.input}
          />
        </label>

        <label style={styles.label}>
          Code:
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            style={styles.input}
          />
        </label>

        <label style={styles.label}>
          Discount:
          <input
            type="number"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            required
            style={styles.input}
          />
        </label>

        <label style={styles.label}>
          Discount Type:
          <select
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            required
            style={styles.select}
          >
            <option value="">Select Type</option>
            <option value="percentage">Percentage</option>
            <option value="fixed">Fixed Amount</option>
          </select>
        </label>

        <label style={styles.label}>
          Applicable Products:
          <input
            type="text"
            value={applicableProducts.join(", ")}
            onChange={handleApplicableProductsChange}
            style={styles.input}
          />
        </label>

        <div className="form-group mb-3" style={styles.dropdownGroup}>
          <label htmlFor="category" style={styles.dropdownLabel}>
            Category
          </label>
          <select
            id="category"
            name="category"
            className="form-control"
            value={applicableCategories}
            onChange={handleApplicableCategoriesChange}
            style={styles.select}
          >
            <option value="">Select Category</option>
            {categories?.map((category) => (
              <option key={category._id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3" style={styles.dropdownGroup}>
          <label htmlFor="brand" style={styles.dropdownLabel}>
            Brand
          </label>
          <select
            id="brand"
            name="brand"
            className="form-control"
            value={applicableBrands}
            onChange={handleApplicableBrandsChange}
            style={styles.select}
          >
            <option value="">Select Brand</option>
            {brands.map((brand) => (
              <option key={brand.name} value={brand.name}>
                {brand?.name}
              </option>
            ))}
          </select>
        </div>

        <label style={styles.label}>
          Terms and Conditions:
          <textarea
            value={TnC}
            onChange={(e) => setTnc(e.target.value)}
            style={styles.textarea}
          />
        </label>

        <label style={styles.label}>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={styles.textarea}
          />
        </label>

        <label style={styles.label}>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
            style={styles.input}
          />
        </label>

        <label style={styles.label}>
          Usage Limit:
          <input
            type="number"
            value={usageLimit}
            onChange={(e) => setUsageLimit(e.target.value)}
            required
            style={styles.input}
          />
        </label>

        <label style={styles.label}>
          Welcome Coupon:
          <input
            type="checkbox"
            checked={isWelcome}
            onChange={() => setIsWelcome(!isWelcome)}
            style={styles.checkbox}
          />
        </label>

        <label style={styles.label}>
          Active:
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            style={styles.checkbox}
          />
        </label>

        <label style={styles.label}>
          Images:
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={styles.fileInput}
          />
        </label>

        <button type="submit" disabled={isLoading} style={styles.button}>
          {isLoading ? "Adding..." : "Add Coupon"}
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    width: "90%",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  textarea: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    minHeight: "100px",
  },
  checkbox: {
    width: "20px",
    height: "20px",
  },
  fileInput: {
    padding: "10px",
  },
  button: {
    padding: "10px 15px",
    fontSize: "18px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    alignSelf: "center",
  },
  dropdownGroup: {
    marginBottom: "15px",
  },
  dropdownLabel: {
    marginBottom: "5px",
  },
};

export default AddCoupon;

// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import apiConfig from "../../../apiConfig";
// import "./AddFlashSale.css";

// const AddFlashSale = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//   } = useForm();

//   const [images, setImages] = useState([]);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [categories, setCategories] = useState([]);
//   const [brands, setBrands] = useState([]);
//   const [selectedCategories, setSelectedCategories] = useState([""]);
//   const [selectedBrands, setSelectedBrands] = useState([""]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const categoryResponse = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.getCategory}`
//         );
//         setCategories(categoryResponse.data);

//         const brandResponse = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
//         );
//         setBrands(brandResponse.data);
//       } catch (error) {
//         console.error("Error fetching categories and brands:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const isValidObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id.trim());

//   const onSubmit = async (data) => {
//     setIsSubmitting(true);
//     setErrorMessage("");

//     const formData = new FormData();

//     formData.append("isVariation", data.isVariation === "true");

//     if (data.products) {
//       data.products
//         .split(",")
//         .map((id) => id.trim())
//         .filter(isValidObjectId)
//         .forEach((id) => formData.append("products", id));
//     }

//     if (data.isVariation === "true" && data.variations) {
//       data.variations
//         .split(",")
//         .map((id) => id.trim())
//         .filter(isValidObjectId)
//         .forEach((id) => formData.append("variations", id));
//     }

//     selectedCategories.forEach((category) => {
//       if (category) formData.append("categories", category);
//     });

//     selectedBrands.forEach((brand) => {
//       if (brand) formData.append("brands", brand);
//     });

//     formData.append("discountPercentage", data.discountPercentage || 0);
//     formData.append("saleStartTime", data.saleStartTime || "");
//     formData.append("saleEndTime", data.saleEndTime || "");

//     images.forEach((image) => {
//       formData.append("images", image);
//     });

//     try {
//       const response = await axios.post(
//         `${apiConfig.baseUrl}${apiConfig.AddFlashSale}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       console.log("Response:", response.data);
//       alert("Flash sale added successfully!");
//       reset();
//       setImages([]);
//       setSelectedCategories([""]);
//       setSelectedBrands([""]);
//     } catch (error) {
//       console.error("Error:", error.response?.data);
//       setErrorMessage(error.response?.data?.message || "Something went wrong");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const addCategoryField = () => {
//     setSelectedCategories([...selectedCategories, ""]);
//   };

//   const addBrandField = () => {
//     setSelectedBrands([...selectedBrands, ""]);
//   };

//   const handleCategoryChange = (index, value) => {
//     const updatedCategories = [...selectedCategories];
//     updatedCategories[index] = value;
//     setSelectedCategories(updatedCategories);
//   };

//   const handleBrandChange = (index, value) => {
//     const updatedBrands = [...selectedBrands];
//     updatedBrands[index] = value;
//     setSelectedBrands(updatedBrands);
//   };

//   return (
//     <div className="flash-sale-form">
//       <h2>Add Flash Sale</h2>

//       {errorMessage && <p className="error">{errorMessage}</p>}

//       <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
//         <div>
//           <label>Product IDs</label>
//           <input
//             type="text"
//             {...register("products")}
//             placeholder="Enter Product IDs separated by commas (Optional)"
//           />
//         </div>

//         <div>
//           <label>Is Variation</label>
//           <select {...register("isVariation")}>
//             <option value="false">false</option>
//             <option value="true">true</option>
//           </select>
//         </div>

//         <div>
//           <label>Variation IDs (if applicable)</label>
//           <input
//             type="text"
//             {...register("variations")}
//             placeholder="Enter Variation IDs separated by commas (Optional)"
//           />
//         </div>

//         <div>
//           <label>Categories</label>
//           {selectedCategories.map((category, index) => (
//             <select
//               key={index}
//               value={category}
//               onChange={(e) => handleCategoryChange(index, e.target.value)}
//             >
//               <option value="">Select Category (Optional)</option>
//               {categories.map((category) => (
//                 <option key={category._id} value={category._id}>
//                   {category.name}
//                 </option>
//               ))}
//             </select>
//           ))}
//           <button type="button" onClick={addCategoryField}>
//             Add Category
//           </button>
//         </div>

//         <div>
//           <label>Brands</label>
//           {selectedBrands.map((brand, index) => (
//             <select
//               key={index}
//               value={brand}
//               onChange={(e) => handleBrandChange(index, e.target.value)}
//             >
//               <option value="">Select Brand (Optional)</option>
//               {brands.map((brand) => (
//                 <option key={brand._id} value={brand._id}>
//                   {brand.name}
//                 </option>
//               ))}
//             </select>
//           ))}
//           <button type="button" onClick={addBrandField}>
//             Add Brand
//           </button>
//         </div>

//         <div>
//           <label>Discount Percentage</label>
//           <input
//             type="number"
//             step="0.01"
//             {...register("discountPercentage", { min: 1, max: 99 })}
//             placeholder="Enter Discount Percentage (Optional)"
//           />
//         </div>

//         <div>
//           <label>Sale Start Time</label>
//           <input type="datetime-local" {...register("saleStartTime")} />
//         </div>

//         <div>
//           <label>Sale End Time</label>
//           <input type="datetime-local" {...register("saleEndTime")} />
//         </div>

//         <div>
//           <label>Upload Images</label>
//           <input
//             type="file"
//             accept="image/*"
//             multiple
//             onChange={handleImageChange}
//           />
//         </div>

//         <button type="submit" disabled={isSubmitting}>
//           {isSubmitting ? "Submitting..." : "Add to Flash Sale"}
//         </button>
//       </form>
//     </div>
//   );
// };

// export default AddFlashSale;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import "./AddFlashSale.css";

const AddFlashSale = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [images, setImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([""]);
  const [selectedBrands, setSelectedBrands] = useState([""]);
  const [variationIds, setVariationIds] = useState([""]); // New state for variation IDs

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.getCategory}`
        );
        setCategories(categoryResponse.data);

        const brandResponse = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetAllBrands}`
        );
        setBrands(brandResponse.data);
      } catch (error) {
        console.error("Error fetching categories and brands:", error);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const isValidObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id.trim());

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();

    formData.append("isVariation", data.isVariation === "true");

    // Process product IDs
    if (data.products) {
      data.products
        .split(",")
        .map((id) => id.trim())
        .filter(isValidObjectId)
        .forEach((id) => formData.append("products", id));
    }

    // Process variation IDs from state
    if (data.isVariation === "true") {
      variationIds.forEach((id) => {
        if (isValidObjectId(id)) {
          formData.append("variations", id); // Append each valid variation ID
        }
      });
    }

    // Handle categories
    selectedCategories.forEach((category) => {
      if (category) formData.append("categories", category);
    });

    // Handle brands
    selectedBrands.forEach((brand) => {
      if (brand) formData.append("brands", brand);
    });

    formData.append("discountPercentage", data.discountPercentage || 0);
    formData.append("saleStartTime", data.saleStartTime || "");
    formData.append("saleEndTime", data.saleEndTime || "");

    images.forEach((image) => {
      formData.append("images", image);
    });

    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}${apiConfig.AddFlashSale}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Response:", response.data);
      alert("Flash sale added successfully!");
      reset();
      setImages([]);
      setSelectedCategories([""]);
      setSelectedBrands([""]);
      setVariationIds([""]); // Reset variation IDs after submission
    } catch (error) {
      console.error("Error:", error.response?.data);
      setErrorMessage(error.response?.data?.message || "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addVariationField = () => {
    setVariationIds([...variationIds, ""]); // Add new empty variation ID field
  };

  const handleVariationChange = (index, value) => {
    const updatedVariations = [...variationIds];
    updatedVariations[index] = value; // Update specific variation ID
    setVariationIds(updatedVariations);
    console.log("Updated Variation IDs:", updatedVariations); // Log to verify changes
  };

  const addCategoryField = () => {
    setSelectedCategories([...selectedCategories, ""]);
  };

  const addBrandField = () => {
    setSelectedBrands([...selectedBrands, ""]);
  };

  const handleCategoryChange = (index, value) => {
    const updatedCategories = [...selectedCategories];
    updatedCategories[index] = value;
    setSelectedCategories(updatedCategories);
  };

  const handleBrandChange = (index, value) => {
    const updatedBrands = [...selectedBrands];
    updatedBrands[index] = value;
    setSelectedBrands(updatedBrands);
  };

  return (
    <div className="flash-sale-form">
      <h2>Add Flash Sale</h2>

      {errorMessage && <p className="error">{errorMessage}</p>}

      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div>
          <label>Product IDs</label>
          <input
            type="text"
            {...register("products")}
            placeholder="Enter Product IDs separated by commas (Optional)"
          />
        </div>

        <div>
          <label>Is Variation</label>
          <select {...register("isVariation")}>
            <option value="false">false</option>
            <option value="true">true</option>
          </select>
        </div>

        {/* Variation IDs Input */}
        {variationIds.map((variationId, index) => ( // Change from selectedCategories to variationIds
          <div key={index}>
            <label>Variation ID {index + 1}</label>
            <input
              type="text"
              value={variationId}
              onChange={(e) => handleVariationChange(index, e.target.value)}
              placeholder="Enter Variation ID"
            />
          </div>
        ))}
        <button type="button" onClick={addVariationField}>
          Add Variation ID
        </button>

        <div>
          <label>Categories</label>
          {selectedCategories.map((category, index) => (
            <select
              key={index}
              value={category}
              onChange={(e) => handleCategoryChange(index, e.target.value)}
            >
              <option value="">Select Category (Optional)</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
          ))}
          <button type="button" onClick={addCategoryField}>
            Add Category
          </button>
        </div>

        <div>
          <label>Brands</label>
          {selectedBrands.map((brand, index) => (
            <select
              key={index}
              value={brand}
              onChange={(e) => handleBrandChange(index, e.target.value)}
            >
              <option value="">Select Brand (Optional)</option>
              {brands.map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </select>
          ))}
          <button type="button" onClick={addBrandField}>
            Add Brand
          </button>
        </div>

        <div>
          <label>Discount Percentage</label>
          <input
            type="number"
            step="0.01"
            {...register("discountPercentage", { min: 1, max: 99 })}
            placeholder="Enter Discount Percentage (Optional)"
          />
        </div>

        <div>
          <label>Sale Start Time</label>
          <input type="datetime-local" {...register("saleStartTime")} />
        </div>

        <div>
          <label>Sale End Time</label>
          <input type="datetime-local" {...register("saleEndTime")} />
        </div>

        <div>
          <label>Upload Images</label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </div>

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Add to Flash Sale"}
        </button>
      </form>
    </div>
  );
};

export default AddFlashSale;

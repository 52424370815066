import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiConfig from "../../../apiConfig";
import { Modal, Button } from "react-bootstrap";

const ViewProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [trendingProducts, setTrendingProducts] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchProducts(currentPage);
  }, [currentPage]);

  const fetchProducts = async (page) => {
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.getAllProduct}?page=${page}&limit=16`
      );
      if (response.status === 200) {
        console.log("response.data.data", response.data.data);

        setProducts(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.getCategory}`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleAddTrendingProduct = async (product) => {
    try {
      const response = await axios.put(
        `${apiConfig.baseUrl}/admin/add/trendingProduct/${product._id}`
      );

      if (response.status === 200) {
        if (!trendingProducts.some((p) => p._id === product._id)) {
          setTrendingProducts((prevTrendingProducts) => [
            ...prevTrendingProducts,
            product,
          ]);
          alert(
            `Product "${product.name}" has been added to the trending list.`
          );
        } else {
          alert(`Product "${product.name}" is already in the trending list.`);
        }
      } else {
        alert(`Failed to add product "${product.name}" to the trending list.`);
      }
    } catch (error) {
      console.error("Error adding product to trending:", error);
      alert("An error occurred while adding the product to the trending list.");
    }
  };

  const handleRemoveTrendingProduct = (productId) => {
    setTrendingProducts((prevTrendingProducts) =>
      prevTrendingProducts.filter((p) => p._id !== productId)
    );
    alert("Product has been removed from the trending list.");
  };

  const buttonStyles = {
    addButton: {
      backgroundColor: "green",
      color: "white",
      padding: "5px 10px",
      marginRight: "5px",
      border: "none",
      cursor: "pointer",
    },
    removeButton: {
      backgroundColor: "red",
      color: "white",
      padding: "5px 10px",
      border: "none",
      cursor: "pointer",
    },
  };

  const handleDelete = async (productId) => {
    // console.log("Product deleted successfully:", productId);

    setLoading(true);
    try {
      const url = `${apiConfig.baseUrl}${apiConfig.deleteProducts}${productId}`;
      // console.log("Attempting to delete product with ID:", productId);

      const response = await axios.delete(url);

      console.log("Product deleted successfully:", response.data);
      setProducts(products.filter((product) => product._id !== productId));
      setShowDeleteModal(false);
      // alert("Product deleted successfully");
    } catch (error) {
      if (error.response) {
        console.error("Error deleting product:", error.response.data);
        alert(
          `Error deleting product: ${
            error.response.data.message || error.response.data
          }`
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("No response received from server");
      } else {
        console.error("Error setting up request:", error.message);
        alert("Error setting up request: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEditingClick = (product) => {
    navigate(`/admin/edit/${product._id}`, { state: { product } });
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const filteredProducts = searchCategory
    ? products.filter(
        (product) =>
          product.category.toLowerCase() === searchCategory.toLowerCase()
      )
    : products;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRefresh = async () => {
    await fetchProducts();
  };

  return (
    <div>
      <button
        onClick={handleRefresh}
        style={{
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#28A745",
          color: "#fff",
          fontSize: "16px",
          cursor: "pointer",
          margin: "0 20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#218838")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#28A745")}
      >
        Refresh Data
      </button>
      <h1>View Products</h1>
      <input
        type="text"
        placeholder="Search by category"
        value={searchCategory}
        onChange={(e) => setSearchCategory(e.target.value)}
      />

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  ID
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Name
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Brand Name
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Description
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  RegularPrice
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  SalePrice
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Specifications
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Stock
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Discount
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Category
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  SubCategory
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Images
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Size
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Actions
                </th>
                <th style={{ border: "1px solid black", padding: "8px" }}>
                  Trending Product Tag
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                    fontSize: "2rem",
                  }}
                >
                  Variations
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts?.map((product) => (
                <tr key={product._id}>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product._id}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.title || "No Title"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.brand || "No Brand"}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      color: "red",
                    }}
                  >
                    {product.description || "No Description"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    ₹{product.regularPrice ?? "N/A"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    ₹{product.salePrice ?? "N/A"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Key
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.specifications.map((spec, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {spec.key}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {spec.values.join(" , ")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.stock ?? "N/A"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.discount ?? "N/A"}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.category}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.subcategory}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <img
                      src={product.images[0]}
                      alt={product.title}
                      style={{
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(product.images[0])}
                    />
                  </td>

                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {Array.isArray(product.size)
                      ? product.size.join(", ")
                      : "No sizes available"}
                  </td>

                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    <button onClick={() => handleEditingClick(product)}>
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setShowDeleteModal(true);
                        setProductIdToDelete(product._id);
                      }}
                      style={{ color: "red" }}
                    >
                      Delete
                    </button>
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {trendingProducts.some((p) => p._id === product._id) ? (
                      <button
                        style={buttonStyles.removeButton}
                        onClick={() => handleRemoveTrendingProduct(product._id)}
                      >
                        Remove Trending Tag
                      </button>
                    ) : (
                      <button
                        style={buttonStyles.addButton}
                        onClick={() => handleAddTrendingProduct(product)}
                      >
                        Add Trending Tag
                      </button>
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "8px" }}>
                    {product.variations && product.variations.length > 0 ? (
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              variationId
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Title
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Color
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Price
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              RAM
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              ROM
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Stock
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Sold
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Image
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {product.variations.map((variations, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.id || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.variationTitle || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.color || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                ₹{variations.price || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.ram || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.rom || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.stock || "N/A"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.sold || 0}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                {variations.images &&
                                variations.images.length > 0 ? (
                                  <img
                                    src={variations.images[0]}
                                    alt="Variation Image"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                ) : (
                                  "No Image"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      "No Variations"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: "20px" }}>
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              style={{
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                backgroundColor: "#007BFF",
                color: "#fff",
                fontSize: "16px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                backgroundColor: "#007BFF",
                color: "#fff",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Next
            </button>
          </div>
        </>
      )}

      <Modal show={selectedImage !== null} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Selected" style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {showDeleteModal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "30%",
            height: "20%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "whitesmoke",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            alignItems: "center",
            justifyContent: "center",
            padding: "25px",
            border: "2px solid #ddd",
          }}
        >
          <h6>Are you sure , you want to delete this product</h6>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            variant="danger"
            onClick={() => {
              console.log("Product ID to delete:", productIdToDelete);
              handleDelete(productIdToDelete);
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default ViewProduct;

import React, { useEffect, useState } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";

const GetFlashSale = () => {
  const [flashSales, setFlashSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedFlashSale, setSelectedFlashSale] = useState(null);
  const [formData, setFormData] = useState({
    salePrice: "",
    saleStartTime: "",
    saleEndTime: "",
    isVariation: false,
    variationId: "",
  });
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    const fetchFlashSales = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetFlashSale}`
        );
        setFlashSales(response.data.flashSales);
      } catch (error) {
        console.error("Error fetching flash sales:", error);
        setError(error.response?.data?.message || "Error fetching flash sales");
      } finally {
        setLoading(false);
      }
    };

    fetchFlashSales();
  }, []);

  const handleEdit = (flashSale) => {
    setSelectedFlashSale(flashSale);
    setFormData({
      salePrice: flashSale.salePrice,
      saleStartTime: new Date(flashSale.saleStartTime)
        .toISOString()
        .slice(0, 16),
      saleEndTime: new Date(flashSale.saleEndTime).toISOString().slice(0, 16),
      isVariation: flashSale.isVariation,
      variationId: flashSale.variationId || "",
    });
  };

  const handleDelete = async (flashSaleId) => {
    setActionLoading(true);
    try {
      await axios.delete(
        `${apiConfig.baseUrl}${apiConfig.DeleteFlashSale}${flashSaleId}`
      );
      setFlashSales((prevSales) =>
        prevSales.filter((sale) => sale._id !== flashSaleId)
      );
      alert("Flash sale deleted successfully.");
    } catch (error) {
      console.error("Error deleting flash sale:", error);
      alert(error.response?.data?.message || "Error deleting flash sale.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const { salePrice, saleStartTime, saleEndTime, isVariation, variationId } =
      formData;
    const flashSaleId = selectedFlashSale._id;

    setActionLoading(true);

    try {
      const response = await axios.put(
        `${apiConfig.baseUrl}${apiConfig.UpdateFlashSale}${flashSaleId}`,
        { salePrice, saleStartTime, saleEndTime, isVariation, variationId }
      );
      setFlashSales((prevSales) =>
        prevSales.map((sale) =>
          sale._id === flashSaleId ? response.data.flashSale : sale
        )
      );
      alert("Flash sale updated successfully.");
      setSelectedFlashSale(null);
    } catch (error) {
      console.error("Error updating flash sale:", error);
      alert(error.response?.data?.message || "Error updating flash sale.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div>
      <h1>Flash Sales</h1>
      {flashSales.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Variation ID</th>
              <th>Sale Price</th>
              <th>Original Price</th>
              <th>Discount</th>
              <th>Sale Start Time</th>
              <th>Sale End Time</th>
              <th>Images</th>
              <th>Is Variation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {flashSales.map((sale) => (
              <tr key={sale._id}>
                <td>{sale.productId}</td>
                <td>{sale.variationId}</td>
                <td>{sale.salePrice}</td>
                <td>{sale.originalPrice}</td>
                <td>{sale.discount}</td>
                <td>{new Date(sale.saleStartTime).toLocaleString()}</td>
                <td>{new Date(sale.saleEndTime).toLocaleString()}</td>
                <td>
                  {sale.image.map((img, index) => (
                    <img
                      key={index}
                      src={`${apiConfig.baseUrl}/${img}`}
                      alt={`Sale Image ${index + 1}`}
                      style={{ width: "50px", height: "50px" }}
                    />
                  ))}
                </td>
                <td>{sale.isVariation ? "Yes" : "No"}</td>
                <td>
                  <button onClick={() => handleEdit(sale)}>Edit</button>
                  <button
                    onClick={() => handleDelete(sale._id)}
                    disabled={actionLoading}
                  >
                    {actionLoading ? "Deleting..." : "Delete"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No flash sales found.</p>
      )}

      {selectedFlashSale && (
        <form
          onSubmit={handleUpdate}
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            marginTop: "20px",
          }}
        >
          <h3>Edit Flash Sale</h3>
          <label>
            Sale Price:
            <input
              type="number"
              name="salePrice"
              value={formData.salePrice}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </label>
          <label>
            Sale Start Time:
            <input
              type="datetime-local"
              name="saleStartTime"
              value={formData.saleStartTime}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </label>
          <label>
            Sale End Time:
            <input
              type="datetime-local"
              name="saleEndTime"
              value={formData.saleEndTime}
              onChange={handleChange}
              required
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </label>
          <label>
            Is Variation:
            <input
              type="checkbox"
              name="isVariation"
              checked={formData.isVariation}
              onChange={handleChange}
              style={{ margin: "0 10px 0 0" }}
            />
          </label>
          {formData.isVariation && (
            <label>
              Variation ID:
              <input
                type="text"
                name="variationId"
                value={formData.variationId}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "14px",
                }}
              />
            </label>
          )}
          <button
            type="submit"
            disabled={actionLoading}
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            {actionLoading ? "Updating..." : "Update Flash Sale"}
          </button>
          <button
            type="button"
            onClick={() => setSelectedFlashSale(null)}
            style={{
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
};

export default GetFlashSale;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import apiConfig from "../../../apiConfig";
// import QRCode from "qrcode.react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// const ShippingLabel = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loader, setloader] = useState(false);
//   const [error, setError] = useState(null);
//   const [upiId, setUpiId] = useState("");

//   useEffect(() => {
//     const fetchUpiId = async () => {
//       try {
//         const response = await axios.get(
//           `${apiConfig.baseUrl}${apiConfig.GetBankAllDetails}`
//         );
//         const bankDetails = response.data.data;
//         if (bankDetails.length > 0) {
//           setUpiId(bankDetails[0].UpiId);
//         } else {
//           setError("Please add bank detail");
//         }
//       } catch (err) {
//         console.error("Error fetching bank details:", err);
//         setError("Failed to load bank details.");
//       }
//     };

//     fetchAcceptedOrders();
//     fetchUpiId();
//   }, []);

//   const fetchAcceptedOrders = async () => {
//     console.log("Fetching all accepted orders");
//     try {
//       setloader(true);
//       const response = await axios.get(
//         `${apiConfig.baseUrl}${apiConfig.GetAcceptedOrders}`

//       );
//       setloader(false);
//       if (response.data && response.data.orders.length > 0) {
//         setOrders(response.data.orders);
//         console.log("neetu",response.data.orders)
//       } else {
//         setError("you have not any accpeted orders");
//       }
//     } catch (err) {
//       setloader(false);
//       console.error("Error fetching accepted orders:", err);
//       setError("Failed to load accepted orders.");
//     } finally {
//       setloader(false);
//       setLoading(false);
//     }
//   };

//   const downloadPDF = async (order) => {
//     const element = document.getElementById(`order-${order._id}`);
//     const button = document.getElementById(`download-button-${order._id}`);

//     if (!element) {
//       console.error("Element not found for order:", order._id);
//       return;
//     }

//     console.log("Generating PDF for order:", order._id);

//     try {
//       if (button) button.style.display = "none";

//       const canvas = await html2canvas(element);
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, "PNG", 0, 0);
//       pdf.save(`shipping_label_${order._id}.pdf`);
//       console.log("PDF generated successfully for order:", order._id);
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     } finally {
//       if (button) button.style.display = "inline-block";
//     }
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   if (orders.length === 0) {
//     return <div>No accepted orders found.</div>;
//   }

//   const handleRefresh = async () => {
//     await fetchAcceptedOrders();
//   };

//   return (
//     <div
//       style={{
//         padding: "20px",
//         fontFamily: "Arial, sans-serif",
//         border: "4px solid black",
//       }}
//     >
//       <h1 style={{ textAlign: "center" }}>Shipping Label</h1>
//       <button
//         onClick={handleRefresh}
//         style={{
//           padding: "10px 20px",
//           border: "none",
//           borderRadius: "5px",
//           backgroundColor: "#28A745",
//           color: "#fff",
//           fontSize: "16px",
//           cursor: "pointer",
//           margin: "0 20px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//           transition: "background-color 0.3s ease",
//         }}
//         onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#218838")}
//         onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#28A745")}
//       >
//         Refresh Data
//       </button>
//       {orders.map((order) => (
//         <div
//           key={order._id}
//           id={`order-${order._id}`}
//           style={{
//             marginBottom: "20px",
//             borderBottom: "1px solid #ccc",
//             paddingBottom: "10px",
//           }}
//         >
//           <div
//             style={{
//               borderRight: "2px solid black",
//               borderTop: "2px solid black",
//               borderBottom: "2px solid black",
//               padding: "5px",
//               width: "150px",
//               height: "100px",
//               marginLeft: "500px",
//               marginTop: "80px",
//               display: "inline-block",
//               textAlign: "center",
//             }}
//           >
//             <strong>Order Date: </strong>
//             <br />
//             {new Date(order.createdAt).toLocaleDateString()}
//             <br />
//             {new Date(order.createdAt).toLocaleTimeString()}
//           </div>
//           <br />

//           <div
//             className="from"
//             style={{
//               border: "2px solid black",
//               width: "470px",
//               height: "100px",
//               marginTop: "-100px",
//               marginLeft: "30px",
//             }}
//           >
//             <h5 style={{ marginLeft: "10px" }}>From:</h5>
//             <p style={{ marginLeft: "10px" }}>Bharat COD</p>
//             <p style={{ marginLeft: "10px" }}>GSTIN:</p>
//           </div>
//           <br />

//           <div
//             style={{
//               border: "2px solid black",
//               padding: "5px",
//               borderRadius: "5px",
//               width: "620px",
//               height: "260px",
//               marginLeft: "30px",
//             }}
//           >
//             <h5>To:</h5>
//             <p>Customer Details</p>

//             {console.log("orders", orders)}

//             {/* <div>
//               <div style={{ height: "250px" }}>
//                 {orders && orders.length > 0 && (
//                   <div>
//                     <div style={{ height: "250px" }}>
//                       <p>{orders[0].address?.name || ""}</p>
//                       <p>
//                         {orders[0].address?.email || ""} Phone:{" "}
//                         {orders[0].address?.phone || ""}
//                       </p>
//                       <p>{orders[0].address?.location || ""}</p>
//                       <p>
//                         {orders[0].address?.city || ""},{" "}
//                         {orders[0].address?.state || ""}{" "}
//                         {orders[0].address?.pincode || ""}
//                       </p>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div> */}
//           </div>
//           <br />
//           <div>
//             <strong style={{ marginLeft: "30px" }}>Items:</strong>
//             <table
//               style={{
//                 width: "50%",
//                 borderCollapse: "collapse",
//                 marginTop: "10px",
//                 marginLeft: "30px",
//               }}
//             >
//               <thead>
//                 <tr>
//                   <th style={{ border: "1px solid #ddd", padding: "8px" }}>
//                     Product ID
//                   </th>
//                   <th style={{ border: "1px solid #ddd", padding: "8px" }}>
//                     Product Name
//                   </th>
//                   <th style={{ border: "1px solid #ddd", padding: "8px" }}>
//                     Quantity
//                   </th>
//                   <th style={{ border: "1px solid #ddd", padding: "8px" }}>
//                     Sale Price
//                   </th>
//                   <th style={{ border: "1px solid #ddd", padding: "8px" }}>
//                     Shipping Price
//                   </th>
//                   <th style={{ border: "1px solid #ddd", padding: "8px" }}>
//                     Total Amount
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {order.items.map((item) => (
//                   <tr key={item._id}>
//                     <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                       {item.productId ? item.productId._id : "N/A"}
//                     </td>
//                     <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                       {item.title}
//                     </td>
//                     <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                       {item.quantity}
//                     </td>

//                     <td>₹{order.totalPrice ?? 0}</td>
//                     <td>₹{order.shippingCost ?? 0}</td>

//                     <td>
//                       ₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <br />
//           <h5 style={{ marginLeft: "30px" }}>Online Payment</h5>
//           <div
//             style={{
//               border: "2px solid black",
//               padding: "5px",
//               borderRadius: "5px",
//               width: "120px",
//               height: "120px",
//               marginLeft: "30px",
//             }}
//           >
//             <QRCode
//               value={`upi://pay?pa=${upiId}&pn=BharatCod&am=${
//                 (order.totalPrice ?? 0) + (order.shippingCost ?? 0)
//               }&cu=INR&tr=${
//                 order.orderID
//               }&tn=Order%20ID%3A%20${encodeURIComponent(order.orderID)}`}
//               size={100}
//             />
//           </div>
//           <br />
//           <button
//             id={`download-button-${order._id}`}
//             onClick={() => downloadPDF(order)}
//             style={{ marginLeft: "30px", display: "inline-block" }}
//           >
//             Download PDF
//           </button>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ShippingLabel;

import React, { useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ShippingLabel = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loader, setloader] = useState(false);
  const [error, setError] = useState(null);
  const [upiId, setUpiId] = useState("");

  useEffect(() => {
    const fetchUpiId = async () => {
      try {
        const response = await axios.get(
          `${apiConfig.baseUrl}${apiConfig.GetBankAllDetails}`
        );
        const bankDetails = response.data.data;
        if (bankDetails.length > 0) {
          setUpiId(bankDetails[0].UpiId);
        } else {
          setError("Please add bank detail");
        }
      } catch (err) {
        console.error("Error fetching bank details:", err);
        setError("Failed to load bank details.");
      }
    };

    fetchAcceptedOrders();
    fetchUpiId();
  }, []);

  const fetchAcceptedOrders = async () => {
    try {
      setloader(true);
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.GetAcceptedOrders}`
      );
      setloader(false);
      if (response.data && response.data.orders.length > 0) {
        setOrders(response.data.orders);
        console.log("mishra",response.data.orders)
      } else {
        setError("You have no accepted orders.");
      }
    } catch (err) {
      setloader(false);
      console.error("Error fetching accepted orders:", err);
      setError("Failed to load accepted orders.");
    } finally {
      setloader(false);
      setLoading(false);
    }
  };

  const downloadPDF = async (order) => {
    const element = document.getElementById(`order-${order._id}`);
    const button = document.getElementById(`download-button-${order._id}`);

    if (!element) {
      console.error("Element not found for order:", order._id);
      return;
    }

    try {
      if (button) button.style.display = "none";

      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`shipping_label_${order._id}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      if (button) button.style.display = "inline-block";
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (orders.length === 0) {
    return <div>No accepted orders found.</div>;
  }

  const handleRefresh = async () => {
    await fetchAcceptedOrders();
  };

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        border: "4px solid black",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Shipping Label</h1>
      <button
        onClick={handleRefresh}
        style={{
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#28A745",
          color: "#fff",
          fontSize: "16px",
          cursor: "pointer",
          margin: "0 20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#218838")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#28A745")}
      >
        Refresh Data
      </button>
      {orders.map((order) => (
        <div
          key={order._id}
          id={`order-${order._id}`}
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid #ccc",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              borderRight: "2px solid black",
              borderTop: "2px solid black",
              borderBottom: "2px solid black",
              padding: "5px",
              width: "150px",
              height: "100px",
              marginLeft: "500px",
              marginTop: "80px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <strong>Order Date: </strong>
            <br />
            {new Date(order.createdAt).toLocaleDateString()}
            <br />
            {new Date(order.createdAt).toLocaleTimeString()}
          </div>
          <br />

          <div
            className="from"
            style={{
              border: "2px solid black",
              width: "470px",
              height: "100px",
              marginTop: "-100px",
              marginLeft: "30px",
            }}
          >
            <h5 style={{ marginLeft: "10px" }}>From:</h5>
            <p style={{ marginLeft: "10px" }}>Bharat COD</p>
            <p style={{ marginLeft: "10px" }}>GSTIN:</p>
          </div>
          <br />

          <div
            style={{
              border: "2px solid black",
              padding: "5px",
              borderRadius: "5px",
              width: "620px",
              height: "260px",
              marginLeft: "30px",
            }}
          >
            <h5>To:</h5>
            <div style={{ height: "250px" }}>
              <p>{order.address?.name || ""}</p>
              <p>
                {order.address?.email || ""} Phone: {order.address?.phone || ""}
              </p>
              <p>{order.address?.location || ""}</p>
              <p>
                {order.address?.city || ""}, {order.address?.state || ""}{" "}
                {order.address?.pincode || ""}
              </p>
            </div>
          </div>
          <br />
          <div>
            <strong style={{ marginLeft: "30px" }}>Items:</strong>
            <table
              style={{
                width: "50%",
                borderCollapse: "collapse",
                marginTop: "10px",
                marginLeft: "30px",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Product ID
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Product Name
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Quantity
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Sale Price
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Shipping Price
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Total Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item) => (
                  <tr key={item._id}>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {item.productId ? item.productId._id : "N/A"}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {item.title}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {item.quantity}
                    </td>

                    <td>₹{item.totalPrice ?? 0}</td>
                    <td>₹{order.shippingCost ?? 0}</td>

                    <td>
                      ₹{(item.totalPrice ?? 0) + (order.shippingCost ?? 0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <h5 style={{ marginLeft: "30px" }}>Online Payment</h5>
          <div
            style={{
              border: "2px solid black",
              padding: "5px",
              borderRadius: "5px",
              width: "120px",
              height: "120px",
              marginLeft: "30px",
            }}
          >
            <QRCode
              value={`upi://pay?pa=${upiId}&am=${order.totalPrice}&cu=INR`}
              size={100}
            />
          </div>
          <br />
          <button
            id={`download-button-${order._id}`}
            onClick={() => downloadPDF(order)}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
              display: "block",
              marginTop: "20px",
              marginBottom: "30px",
              marginLeft: "30px",
            }}
          >
            Download PDF
          </button>
        </div>
      ))}
    </div>
  );
};

export default ShippingLabel;

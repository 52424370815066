// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import QRCode from "qrcode.react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import ReactPaginate from "react-paginate";
// import apiConfig from "../../apiConfig";
// import "./page.css";
// import { useNavigate } from "react-router-dom";
// import DropdownOfStatus from "./status/DropdownOfStatus";
// import BackButton from "./status/BackButton";

// const Dashboard = () => {
//   const navigate = useNavigate();
//   const [orders, setOrders] = useState([]);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [filterOrderId, setFilterOrderId] = useState("");
//   const [filterProductName, setFilterProductName] = useState("");
//   const [currentPage, setCurrentPage] = useState(0);
//   const [ordersPerPage] = useState(10);
//   const tableRef = useRef(null);

//   const fetchOrders = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `${apiConfig.baseUrl}${apiConfig.ReceivedOrder}`
//       );
//       setOrders(response.data);
//       console.log("neetu", response.data);

//       setFilteredOrders(response.data);
//       setLoading(false);
//     } catch (err) {
//       setError();
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchOrders();
//   }, []);

//   useEffect(() => {
//     let filtered = orders;

//     if (filterOrderId) {
//       filtered = filtered.filter((order) =>
//         order.orderID.includes(filterOrderId)
//       );
//     }

//     if (filterProductName) {
//       filtered = filtered.filter((order) =>
//         order.items.some(
//           (item) =>
//             item.productId &&
//             item.productId.title
//               .toLowerCase()
//               .includes(filterProductName.toLowerCase())
//         )
//       );
//     }

//     setFilteredOrders(filtered);
//   }, [filterOrderId, filterProductName, orders]);

//   const downloadPDF = async () => {
//     const input = tableRef.current;
//     if (!input) return;

//     const canvas = await html2canvas(input, {
//       scrollX: 0,
//       scrollY: -window.scrollY,
//       useCORS: true,
//       windowWidth: document.documentElement.scrollWidth,
//       windowHeight: document.documentElement.scrollHeight,
//     });

//     const imgData = canvas.toDataURL("image/png");
//     const pdf = new jsPDF("p", "mm", "a4");

//     const imgWidth = 210;
//     const pageHeight = 295;
//     const imgHeight = (canvas.height * imgWidth) / canvas.width;
//     let heightLeft = imgHeight;

//     let position = 0;

//     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
//     heightLeft -= pageHeight;

//     while (heightLeft > 0) {
//       position = heightLeft - imgHeight;
//       pdf.addPage();
//       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
//       heightLeft -= pageHeight;
//     }

//     pdf.save("order_list.pdf");
//   };

//   const handleRefresh = async () => {
//     await fetchOrders();
//   };

//   // Function to update order status

//   const updateOrderStatus = async (id, newStatus, cancelDetails) => {
//     setLoading(true);
//     try {
//       const data = {
//         updateStatus: newStatus,
//         cancelDetails: cancelDetails,
//       };
//       console.log("Sending data to API:", data);

//       await axios.put(
//         `${apiConfig.baseUrl}${apiConfig.OrderAcceptOrReject}/${id}`,
//         data
//       );

//       alert(`Order ${id} status updated to ${newStatus}`);
//       if (newStatus === "Accepted") {
//         navigate("/admin/Accepted");
//       } else {
//         navigate("/admin/Cancelled");
//       }
//     } catch (error) {
//       alert(`Failed to update order ${id} status. Please try again.`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAccept = (id) => {
//     updateOrderStatus(id, "Accepted", "");
//   };

//   const handleReject = (id) => {
//     const reason = prompt("Please enter the reason for rejecting the order:");
//     if (reason) {
//       updateOrderStatus(id, "Not Accepted", reason);
//     } else {
//       alert("Reject reason is required.");
//     }
//   };

//   const handlePageClick = (event) => {
//     setCurrentPage(event.selected);
//   };

//   //  Track order and update if needed
//   const handleNavigate = () => {
//     navigate("/admin/OrderTracking");
//   };

//   //  Track order and update if needed

//   const offset = currentPage * ordersPerPage;
//   const currentOrders = filteredOrders.slice(offset, offset + ordersPerPage);
//   const pageCount = Math.ceil(filteredOrders.length / ordersPerPage);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   const buttonStyle = {
//     padding: "10px 20px",
//     fontSize: "16px",
//     fontWeight: "bold",
//     color: "white",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     margin: "5px",
//   };

//   return (
//     <div>
//       <BackButton />
//       <div className="filters">
//         <label>
//           Filter by Order ID:
//           <input
//             type="text"
//             value={filterOrderId}
//             onChange={(e) => setFilterOrderId(e.target.value)}
//           />
//         </label>
//         <label>
//           Filter by Product Name:
//           <input
//             type="text"
//             value={filterProductName}
//             onChange={(e) => setFilterProductName(e.target.value)}
//           />
//         </label>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-start",
//             padding: "20px",
//           }}
//         >
//           <DropdownOfStatus />
//         </div>

//         <div>
//           <button
//             onClick={handleNavigate}
//             style={{
//               backgroundColor: "#007BFF",
//               color: "white",
//               border: "none",
//               padding: "10px 20px",
//               borderRadius: "5px",
//               cursor: "pointer",
//               transition: "background-color 0.3s",
//             }}
//           >
//             Order Track and Update
//           </button>
//         </div>
//         <div style={{ textAlign: "right" }}>
//           <button
//             onClick={downloadPDF}
//             style={{
//               padding: "10px 20px",
//               border: "none",
//               borderRadius: "5px",
//               backgroundColor: "#007BFF",
//               color: "#fff",
//               fontSize: "16px",
//               cursor: "pointer",
//               margin: "0 20px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//               transition: "background-color 0.3s ease",
//             }}
//             onMouseOver={(e) =>
//               (e.currentTarget.style.backgroundColor = "#0056b3")
//             }
//             onMouseOut={(e) =>
//               (e.currentTarget.style.backgroundColor = "#007BFF")
//             }
//           >
//             Download Full List of orders as PDF
//           </button>
//           <button
//             onClick={handleRefresh}
//             style={{
//               padding: "10px 20px",
//               border: "none",
//               borderRadius: "5px",
//               backgroundColor: "#28A745",
//               color: "#fff",
//               fontSize: "16px",
//               cursor: "pointer",
//               margin: "0 20px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//               transition: "background-color 0.3s ease",
//             }}
//             onMouseOver={(e) =>
//               (e.currentTarget.style.backgroundColor = "#218838")
//             }
//             onMouseOut={(e) =>
//               (e.currentTarget.style.backgroundColor = "#28A745")
//             }
//           >
//             Refresh Data
//           </button>
//         </div>
//       </div>
//       <div ref={tableRef}>
//         <h1>All Received Orders</h1>
//         <table>
//           <thead>
//             <tr>
//               <th>Items</th>
//               <th>Shipping Price</th>
//               <th>Total Product Price</th>
//               <th>Total Amount</th>
//               <th>Status</th>
//               <th>Order date</th>
//               <th>Order ID</th>
//               <th>User ID</th>
//               <th>Delivery Address</th>
//               <th>Payment Status</th>
//               <th>Payment Mode</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentOrders
//               .filter((order) => order.updateStatus !== "Accepted")
//               .map((order) => (
//                 <tr key={order._id}>
//                   <td>
//                     <table className="sub-table">
//                       <thead>
//                         <tr>
//                           <th>Product ID</th>
//                           <th>Product Name</th>
//                           <th>Quantity</th>
//                           <th>Product Price</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {order.items.map((item) => (
//                           <tr key={item._id}>
//                             <td>
//                               {item.productId || item.variation.variationId}
//                             </td>
//                             <td>
//                               {item.title || item.variation.variationTitle}
//                             </td>
//                             <td>{item.quantity}</td>
//                             <td>₹{item.totalPrice}</td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   </td>
//                   <td>₹{order.shippingCost ?? 0}</td>
//                   <td>₹{order.totalPrice ?? 0}</td>
//                   <td>
//                     ₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}
//                   </td>
//                   <td>{order.status}</td>
//                   <td>
//                     {new Date(order.createdAt).toLocaleDateString()}
//                     <br />
//                     {new Date(order.createdAt).toLocaleTimeString()}
//                   </td>
//                   <td>{order.orderID}</td>
//                   <td>{order.userId}</td>
//                   <td>
//                     {order.address ? (
//                       <>
//                         <div>Name: {order.address.name || "N/A"}</div>
//                         <div>Address: {order.address.location || "N/A"}</div>
//                         <div>{order.address.city || "N/A"}</div>
//                         <div>{order.address.state || "N/A"}</div>
//                         <div>{order.address.pincode || "N/A"}</div>
//                         <div>Phone: {order.address.phone || "N/A"}</div>
//                         <div>Email: {order.address.email || "N/A"}</div>
//                         <div>
//                           Delivery Days: {order.address.deliveryDays || "N/A"}
//                         </div>
//                       </>
//                     ) : (
//                       "Address information is not available"
//                     )}
//                   </td>
//                   <td>{order.paymentStatus}</td>
//                   <td>{order.paymentMode}</td>
//                   <td>
//                     <button
//                       style={{
//                         ...buttonStyle,
//                         backgroundColor: "green",
//                         opacity: loading ? 0.6 : 1,
//                         cursor: loading ? "not-allowed" : "pointer",
//                       }}
//                       onClick={() => handleAccept(order.orderID)}
//                       disabled={loading}
//                     >
//                       {loading ? "Loading..." : "Accept"}
//                     </button>
//                     <button
//                       style={{
//                         ...buttonStyle,
//                         backgroundColor: "red",
//                         opacity: loading ? 0.6 : 1,
//                         cursor: loading ? "not-allowed" : "pointer",
//                       }}
//                       onClick={() => handleReject(order.orderID)}
//                       disabled={loading}
//                     >
//                       {loading ? "Loading..." : "Reject"}
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>
//       <ReactPaginate
//         previousLabel={"previous"}
//         nextLabel={"next"}
//         breakLabel={"..."}
//         pageCount={pageCount}
//         marginPagesDisplayed={2}
//         pageRangeDisplayed={5}
//         onPageChange={handlePageClick}
//         containerClassName={"pagination"}
//         activeClassName={"active"}
//       />
//     </div>
//   );
// };

// export default Dashboard;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactPaginate from "react-paginate";
import apiConfig from "../../apiConfig";
import "./page.css";
import { useNavigate } from "react-router-dom";
import DropdownOfStatus from "./status/DropdownOfStatus";
import BackButton from "./status/BackButton";

const Dashboard = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [ordersPerPage] = useState(10);
  const tableRef = useRef(null);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiConfig.baseUrl}${apiConfig.ReceivedOrder}`
      );
      setOrders(response.data);
      console.log("neetu", response.data);

      setFilteredOrders(response.data);
      setLoading(false);
    } catch (err) {
      setError();
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    let filtered = orders;

    if (filterOrderId) {
      filtered = filtered.filter((order) =>
        order.orderID.includes(filterOrderId)
      );
    }

    if (filterProductName) {
      filtered = filtered.filter((order) =>
        order.items.some(
          (item) =>
            item.productId &&
            item.productId.title
              .toLowerCase()
              .includes(filterProductName.toLowerCase())
        )
      );
    }

    setFilteredOrders(filtered);
  }, [filterOrderId, filterProductName, orders]);

  const downloadPDF = async () => {
    const input = tableRef.current;
    if (!input) return;

    const canvas = await html2canvas(input, {
      scrollX: 0,
      scrollY: -window.scrollY,
      useCORS: true,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save("order_list.pdf");
  };

  const handleRefresh = async () => {
    await fetchOrders();
  };

  // Function to update order status

  const updateOrderStatus = async (id, newStatus, cancelDetails) => {
    setLoading(true);
    try {
      const data = {
        updateStatus: newStatus,
        cancelDetails: cancelDetails,
      };
      console.log("Sending data to API:", data);

      await axios.put(
        `${apiConfig.baseUrl}${apiConfig.OrderAcceptOrReject}/${id}`,
        data
      );

      alert(`Order ${id} status updated to ${newStatus}`);
      if (newStatus === "Accepted") {
        navigate("/admin/Accepted");
      } else {
        navigate("/admin/Cancelled");
      }
    } catch (error) {
      alert(`Failed to update order ${id} status. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = (id) => {
    updateOrderStatus(id, "Accepted", "");
  };

  const handleReject = (id) => {
    const reason = prompt("Please enter the reason for rejecting the order:");
    if (reason) {
      updateOrderStatus(id, "Not Accepted", reason);
    } else {
      alert("Reject reason is required.");
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  //  Track order and update if needed
  const handleNavigate = () => {
    navigate("/admin/OrderTracking");
  };

  //  Track order and update if needed

  const offset = currentPage * ordersPerPage;
  const currentOrders = filteredOrders.slice(offset, offset + ordersPerPage);
  const pageCount = Math.ceil(filteredOrders.length / ordersPerPage);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "5px",
  };

  return (
    <div>
      <BackButton />
      <div className="filters">
        <label>
          Filter by Order ID:
          <input
            type="text"
            value={filterOrderId}
            onChange={(e) => setFilterOrderId(e.target.value)}
          />
        </label>
        <label>
          Filter by Product Name:
          <input
            type="text"
            value={filterProductName}
            onChange={(e) => setFilterProductName(e.target.value)}
          />
        </label>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "20px",
          }}
        >
          <DropdownOfStatus />
        </div>

        <div>
          <button
            onClick={handleNavigate}
            style={{
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          >
            Order Track and Update
          </button>
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            onClick={downloadPDF}
            style={{
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#007BFF",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
              margin: "0 20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#0056b3")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#007BFF")
            }
          >
            Download Full List of orders as PDF
          </button>
          <button
            onClick={handleRefresh}
            style={{
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#28A745",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
              margin: "0 20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#218838")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#28A745")
            }
          >
            Refresh Data
          </button>
        </div>
      </div>
      <div ref={tableRef}>
        <h1>All Received Orders</h1>
        <table>
          <thead>
            <tr>
              <th>Items</th>
              <th>Shipping Price</th>
              <th>Total Product Price</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Order date</th>
              <th>Order ID</th>
              <th>User ID</th>
              <th>Delivery Address</th>
              <th>Payment Status</th>
              <th>Payment Mode</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentOrders
              .filter((order) => order.updateStatus !== "Accepted")
              .map((order) => (
                <tr key={order._id}>
                  <td>
                    <table className="sub-table">
                      <thead>
                        <tr>
                          <th>Product ID</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Product Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.items.map((item) => {
                          console.log("itemvv", item.totalPrice);
                          return (
                            <tr key={item._id}>
                              <td>
                                {item?.variation?.variationId
                                  ? item?.variation?.variationId
                                  : item.productId || "N/A"}
                              </td>
                              <td>
                                {item?.variation?.variationTitle
                                  ? item?.variation?.variationTitle
                                  : item.title || "N/A"}
                              </td>
                              <td>{item.quantity}</td>
                              <td>₹{item.totalPrice}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                  {console.log("order.items[0].price", order.items[0].price)}
                  <td>₹{order.shippingCost ?? 0}</td>
                  <td>₹{order.items[0].price ?? 0}</td>
                  <td>
                    ₹{(order.totalPrice ?? 0) + (order.shippingCost ?? 0)}
                  </td>
                  <td>{order.status}</td>
                  <td>
                    {new Date(order.createdAt).toLocaleDateString()}
                    <br />
                    {new Date(order.createdAt).toLocaleTimeString()}
                  </td>
                  <td>{order.orderID}</td>
                  <td>{order.userId}</td>
                  <td>
                    {order.address ? (
                      <>
                        <div>Name: {order.address.name || "N/A"}</div>
                        <div>Address: {order.address.location || "N/A"}</div>
                        <div>{order.address.city || "N/A"}</div>
                        <div>{order.address.state || "N/A"}</div>
                        <div>{order.address.pincode || "N/A"}</div>
                        <div>Phone: {order.address.phone || "N/A"}</div>
                        <div>Email: {order.address.email || "N/A"}</div>
                        <div>
                          Delivery Days: {order.address.deliveryDays || "N/A"}
                        </div>
                      </>
                    ) : (
                      "Address information is not available"
                    )}
                  </td>
                  <td>{order.paymentStatus}</td>
                  <td>{order.paymentMode}</td>
                  <td>
                    <button
                      style={{
                        ...buttonStyle,
                        backgroundColor: "green",
                        opacity: loading ? 0.6 : 1,
                        cursor: loading ? "not-allowed" : "pointer",
                      }}
                      onClick={() => handleAccept(order.orderID)}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Accept"}
                    </button>
                    <button
                      style={{
                        ...buttonStyle,
                        backgroundColor: "red",
                        opacity: loading ? 0.6 : 1,
                        cursor: loading ? "not-allowed" : "pointer",
                      }}
                      onClick={() => handleReject(order.orderID)}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Reject"}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default Dashboard;
